<template>
  <div class="dashboard-accordion" v-has-perms="['document_document_expired_training_list']">
    <template v-for="document in documentList">
      <div class="panel-list" :key="document.id">
        <div class="panel panel-inverse">
          <div :class="`panel-heading ${selectedAccordion === document.id ? 'expanded' : 'collapsed'}`" @click="toggleAccordion(document.id)">
              <h4 class="panel-title">{{document.name}} - {{ 'Customers' | translate }}</h4>
            </div>
            <div class="panel-body" v-if="accordion[document.id]">
              <!-- <training-table :list="list.results"/> -->
              <training-list :isDetail="true" :documentId="selectedAccordion"/>
            </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as TRAINING_TYPES } from './store'
import TrainingList from './TrainingList.vue'

export default {
  name: 'TrainingAccordion',
  components: { TrainingList },
  data () {
    return {
      accordion: {},
      selectedAccordion: undefined
    }
  },
  mounted () {
    this.getDocumentlist()
  },
  computed: {
    ...mapGetters({
      ...TRAINING_TYPES.GENERIC.dashboard.training.LIST.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...TRAINING_TYPES.GENERIC.dashboard.training.LIST.ACTIONS
    }),
    ...mapMutations({
      setList: TRAINING_TYPES.GENERIC.dashboard.training.LIST.MUTATIONS.setList
    }),
    toggleAccordion (id) {
      this.setList({})
      this.documentList.forEach(document => {
        console.log(this.selectedAccordion)
        if (document.id === id && !this.accordion[document.id]) {
          this.$set(this, 'selectedAccordion', id)
          this.accordion[document.id] = true
        } else {
          this.accordion[document.id] = false
        }
      })
    }
  },
  watch: {
    documentList () {
      this.documentList.forEach(document => {
        this.$set(this.accordion, document.id, false)
        this.$set(this, 'selectedAccordion', undefined)
      })
    }
  }
}
</script>
