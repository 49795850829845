<template>
  <div :class="!isDetail ? 'wrapper sidebar-collapsed' : ''" v-has-any-perms="allRolePerm">
    <portal-target
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed">
        <panel :title="panelTitle" :no-button="true">
          <!-- {{yardGantt}} -->
          <div class="col">
            <table class="table b-table table-hover">
              <thead class="">
                <tr class="">
                  <th class="table-b-table-default text-wrap">{{ $t('Report') }}</th>
                  <th class="table-b-table-default text-wrap text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-has-perms="quotationRolePerm">
                  <td>{{ $t('Quotation list in excel')}}</td>
                  <td class="text-right">
                    <b-btn
                      v-has-perms="quotationRolePerm"
                      variant="outline-primary"
                      :title="'Download Excel' | translate"
                      @click.prevent="openWarningDialog('quotations')"
                    >
                      <i class='fas fa-file-excel'></i>
                    </b-btn>
                  </td>
                </tr>
                <tr v-has-perms="expiredTrainingRolePerm">
                  <td>{{ $t('Expired training list in excel')}}</td>
                  <td class="text-right">
                    <b-btn
                      v-has-perms="expiredTrainingRolePerm"
                      variant="outline-primary"
                      :title="'Download Excel' | translate"
                      @click.prevent="openDownloadExpiredDialog('expiredTraining')"
                    >
                      <i class='fas fa-file-excel'></i>
                    </b-btn>
                  </td>
                </tr>
                <tr v-has-perms="notPerformedTrainingRolePerm">
                  <td>{{ $t('List of trainings not performed in excel')}}</td>
                  <td class="text-right">
                    <b-btn
                      v-has-perms="notPerformedTrainingRolePerm"
                      variant="outline-primary"
                      :title="'Download Excel' | translate"
                      @click.prevent="openWarningDialog('notPerformedTraining')"
                    >
                      <i class='fas fa-file-excel'></i>
                    </b-btn>
                  </td>
                </tr>
                <tr v-has-perms="expiringTrainingRolePerm">
                  <td>{{ $t('Training list due in 3 months in Excel')}}</td>
                  <td class="text-right">
                    <b-btn
                      v-has-perms="expiringTrainingRolePerm"
                      variant="outline-primary"
                      :title="'Download Excel' | translate"
                      @click.prevent="openDownloadExpiredDialog('expiringTraining')"
                    >
                      <i class='fas fa-file-excel'></i>
                    </b-btn>
                  </td>
                </tr>
                <tr v-has-perms="expiringMedicalEligibilityRolePerm">
                  <td>{{ $t('1 month medical eligibility deadline list in Excel')}}</td>
                  <td class="text-right">
                    <b-btn
                      v-has-perms="expiringMedicalEligibilityRolePerm"
                      variant="outline-primary"
                      :title="'Download Excel' | translate"
                      @click.prevent="openDownloadExpiredDialog('expiringMedicalEligibility')"
                    >
                      <i class='fas fa-file-excel'></i>
                    </b-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </panel>
      </div>
    </portal-target>
    <router-view />
    <WarningDialog
      v-if="showWarningDialog"
      ref-id="modal-warning"
      :no-close-on-backdrop="true"
      confirm-btn-variant="primary"
      :confirm-btn-disabled="false"
      :title="$t('WARNING')"
      :body="warningDialogMessage"
      :button-confirm-text="'OK' | translate"
      @warning-dialog-close="closeWarningDialogModal"
    >
    </WarningDialog>
    <DownloadExpiredDataModal
      v-if="showDownloadExpiredDialog"
      ref-id="modal-warning"
      :no-close-on-backdrop="true"
      confirm-btn-variant="primary"
      :confirm-btn-disabled="false"
      :body="warningDialogMessage"
      :button-confirm-text="'OK' | translate"
      :dataType="selectedDownloadType"
      @close-modal="closeDownloadExpiredModal"
      @generate-job="createJob"
    >
    </DownloadExpiredDataModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { TYPES as QUOTATION_TYPES } from '../../quotation/quotation/store'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from '../upload-document/store'
import WarningDialog from '@/components/WarningDialog'
import DownloadExpiredDataModal from './DownloadExpiredDataModal'

export default {
  name: 'DownloadsList',
  props: {
    isDetail: { default: false }
  },
  components: {
    WarningDialog,
    DownloadExpiredDataModal
  },
  data () {
    const _data = {
      quotationRolePerm: ['quotation_quotation_generate_quotation_xslx_job'],
      expiredTrainingRolePerm: ['document_uploaddocument_generate_deadline_training_xlsx_job'],
      notPerformedTrainingRolePerm: ['document_uploaddocument_generate_not_carried_out_training_xlsx_job'],
      expiringTrainingRolePerm: ['document_uploaddocument_generate_expiring_training_xlsx_job'],
      expiringMedicalEligibilityRolePerm: ['document_uploaddocument_generate_expiring_health_suitability_xlsx_job'],
      showWarningDialog: false,
      warningDialogMessage: undefined,
      selectedDownloadType: undefined,
      showDownloadExpiredDialog: false
    }
    _data.allRolePerm = [..._data.quotationRolePerm, ..._data.expiredTrainingRolePerm, ..._data.notPerformedTrainingRolePerm, ..._data.expiringTrainingRolePerm, ..._data.expiringMedicalEligibilityRolePerm]
    return _data
  },
  computed: {
    panelTitle () {
      return this.$t('Available reports')
    }
  },
  methods: {
    ...mapActions({
      generateQuotationXlsxJob: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.ACTIONS.generateQuotationXlsxJob,
      generateDeadlineTrainingXlsxJob: UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.ACTIONS.generateDeadlineTrainingXlsxJob,
      generateNotPerformedTrainingXlsxJob: UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.ACTIONS.generateNotPerformedTrainingXlsxJob,
      generateExpiringThreeMonthsTrainingXlsxJob: UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.ACTIONS.generateExpiringThreeMonthsTrainingXlsxJob,
      generateExpiringMedicalEligibilityTrainingXlsxJob: UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.ACTIONS.generateExpiringMedicalEligibilityTrainingXlsxJob
    }),
    openWarningDialog (downloadType) {
      this.$set(this, 'showWarningDialog', true)
      this.$set(this, 'selectedDownloadType', downloadType)
      if (this.selectedDownloadType === 'quotations') {
        this.warningDialogMessage = `${this.$t('The quotation download has been requested, the system will generate an Excel file containing the list of quotations based on applied filters')}.`
      }
      if (this.selectedDownloadType === 'notPerformedTraining') {
        this.warningDialogMessage = `${this.$t('The not performed training download has been requested, the system will generate an Excel file containing the list of quotations based on applied filters')}.`
      }

      this.warningDialogMessage = `${this.warningDialogMessage} ${this.$t('When the required file is ready, the download link will be displayed in the in bar by pressing the downloads icon')}.`
    },
    closeWarningDialogModal () {
      this.$set(this, 'showWarningDialog', false)
      const formData = { }
      if (this.selectedDownloadType === 'quotations') {
        formData.file_name = 'preventivi_completo.xls'
        this.generateQuotationXlsxJob(formData)
      }
      if (this.selectedDownloadType === 'notPerformedTraining') {
        formData.file_name = 'formazione_non_effettuata.xls'
        this.generateNotPerformedTrainingXlsxJob(formData)
      }
    },
    openDownloadExpiredDialog (downloadType) {
      this.$set(this, 'showDownloadExpiredDialog', true)
      this.$set(this, 'selectedDownloadType', downloadType)
    },
    closeDownloadExpiredModal () {
      this.$set(this, 'showDownloadExpiredDialog', false)
      this.$set(this, 'selectedDownloadType', undefined)
    },
    createJob (evt) {
      const formData = Object.assign({}, evt)
      let filename = 'formazione_in_scadenza.xlsx'
      console.log(formData)
      if (this.selectedDownloadType === 'expiredTraining') {
        filename = `formazione_scaduta_${evt.date_end__lte.replaceAll('-', '')}.xlsx`
        formData.file_name = filename
        this.generateDeadlineTrainingXlsxJob(formData).then(
          () => {
            this.closeDownloadExpiredModal()
          }
        )
      }
      if (this.selectedDownloadType === 'expiringMedicalEligibility') {
        filename = `idoneita_medica_in_scadenza_${evt.date_end__gte.replaceAll('-', '')}.xlsx`
        formData.file_name = filename
        this.generateExpiringMedicalEligibilityTrainingXlsxJob(formData).then(
          () => {
            this.closeDownloadExpiredModal()
          }
        )
      }
      if (this.selectedDownloadType === 'expiringTraining') {
        filename = `formazione_in_scadenza_${evt.date_end__gte.replaceAll('-', '')}.xlsx`
        formData.file_name = filename
        this.generateExpiringThreeMonthsTrainingXlsxJob(formData).then(
          () => {
            this.closeDownloadExpiredModal()
          }
        )
      }
    }

  }
}
</script>
