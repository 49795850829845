<template>
  <!-- Add/Edit Form -->
  <b-modal
    id="modalDownloadExpiredData"
    ref="modalDownloadExpiredData"
    :no-enforce-focus="true"
    :no-close-on-backdrop = 'true'
    size='lg'
    @hidden="modalDownloadExpiredDataOnHidden"
  >
    <div slot="modal-title">
      {{ modalTitle | translate }}
    </div>
    <div class="mb-3">
      <p>
        {{ 'Select the reference date to check the deadlines' | translate }}.
      </p>
      <p>
        {{ 'Once the download has been requested, the system will generate an Excel file containing the required data.' | translate }}
      </p>
      <p>
        {{ 'When the required file is ready, the download link will be displayed in the in bar by pressing the icon' | translate }} <i class="fas fa-cloud-download-alt"></i>
      </p>
    </div>
    <b-form>
      <b-form-row>
        <form-thux-horizontal-datetime
          v-if="dataType === 'expiredTraining'"
          :validator="$v.printForm.date_end__lte"
          :label-form="'Reference date' | translate"
          labelColsSm="3"
          labelColsLg="3"
          class-form="col-lg-12 mb-2 mt-2"
          type="date"
          :value="dateRef"
          :placeholder="$t('Enter the date')"
          @change="setDateRef"
        />
        <form-thux-horizontal-datetime
          v-if="dataType === 'expiringTraining' || dataType === 'expiringMedicalEligibility'"
          :validator="$v.printForm.date_end__gte"
          :label-form="'Reference date' | translate"
          labelColsSm="3"
          labelColsLg="3"
          class-form="col-lg-12 mb-2 mt-2"
          type="date"
          :value="dateRef"
          :placeholder="$t('Enter the date')"
          @change="setDateRef"
        />
        <!--
        <form-thux-horizontal-checkbox
          :validator="$v.printForm.worker"
          :label-form="'Worker documents' | translate"
          class-form="col-lg-6 mb-2"
          label-cols-lg="6"
          label-cols-sm="6"
          :value="printForm.worker"
          @change="selectWorkerDocuments($event, 'worker')"
        />
        <form-thux-horizontal-checkbox
          :validator="$v.printForm.worker_training"
          :label-form="'Worker training' | translate"
          class-form="col-lg-6 mb-2"
          label-cols-lg="6"
          label-cols-sm="6"
          :value="printForm.worker_training"
          @change="selectWorkerDocuments($event, 'worker_training')"
        /> -->
      </b-form-row>
    </b-form>
    <div slot="modal-footer">
      <b-btn class="mr-3" variant="default" @click.prevent="closeAndGoToList()">
        {{ 'Cancel' | translate }}
      </b-btn>
      <b-btn
        variant="primary"
        :disabled="$v.printForm.$invalid"
        @click.prevent="closeAndGenerateJob()"
      >
        {{ 'OK' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import FormThuxHorizontalDatetime from '@/components/form-thux-horizontal-components/FormThuxHorizontalDatetimePicker'

export default {
  name: 'DownloadExpiredDataModal',
  props: ['dataType'],
  data () {
    return {
      selectedItemId: undefined,
      showCommonFilters: false,
      showStaffList: false,
      printForm: {},
      dateRef: undefined
    }
  },
  components: {
    'form-thux-horizontal-datetime': FormThuxHorizontalDatetime
  },
  created () { },
  mounted () {
    this.openModalDownloadExpiredData()
  },
  computed: {
    modalTitle () {
      return this.$t('Select reference dates')
    },
    message () {
      return this.$t('Select the personnel whose required documents you want to download. If no name is selected, the documents of all staff will be downloaded.')
    }
  },
  methods: {
    openModalDownloadExpiredData () {
      this.$refs.modalDownloadExpiredData.show()
    },
    modalDownloadExpiredDataOnHidden (evt) {
      this.$emit('close-modal')
    },
    closeAndGoToList (evt) {
      this.$emit('close-modal')
    },
    setDateRef (evt) {
      const date = evt.date
      if (date) {
        if (this.dataType === 'expiredTraining') {
          this.$set(this.printForm, 'date_end__lte', date.format('YYYY-MM-DD'))
        }
        if (this.dataType === 'expiringTraining' || this.dataType === 'expiringMedicalEligibility') {
          this.$set(this.printForm, 'date_end__gte', date.format('YYYY-MM-DD'))
        }
      } else {
        this.$set(this.printForm, 'date_end__lte', undefined)
        this.$set(this.printForm, 'date_end__gte', undefined)
      }
    },
    closeAndGenerateJob () {
      this.$emit('generate-job', this.printForm)
    }
  },
  validations () {
    const vals = {
      printForm: {
        date_end__lte: { },
        date_end__gte: { }
      }
    }
    if (this.dataType === 'expiredTraining') {
      vals.printForm.date_end__lte = { required }
    }
    if (this.dataType === 'expiringTraining' || this.dataType === 'expiringMedicalEligibility') {
      vals.printForm.date_end__gte = { required }
    }
    return vals
  }
}
</script>
