<template>
  <div :class="!isDetail ? 'wrapper sidebar-collapsed' : ''" v-has-perms="rolePerm">
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed">
        <panel :title="panelTitle" :no-button="true">
          <!-- {{yardGantt}} -->
          <div class="col">
            <table class="table b-table table-hover gantt-table" v-if="yardGantt.week_start">
              <thead class="">
                <tr class="">
                  <th class="table-b-table-default text-wrap yard-name"></th>
                  <th :colspan="getMonthColSpan(key, weekList)" class="table-b-table-default text-center month-name" v-for="(weekList, key) in yardGantt.months" :key="key">
                    {{ MONTHS[key.slice(-2)] | translate }}
                  </th>
                  <!-- <th class="table-b-table-default text-center">a</th>
                  <th colspan="10" class="table-b-table-default text-center">b</th>
                  <th colspan="8" class="table-b-table-default text-center">c</th>
                  <th colspan="9" class="table-b-table-default text-center">d</th>
                  <th colspan="9" class="table-b-table-default text-center">e</th>
                  <th colspan="9" class="table-b-table-default text-center">f</th> -->
                </tr>
                <tr class="">
                  <th class="table-b-table-default text-nowrap">
                  <div>
                      {{ 'Yard' | translate }}
                  </div>
                  </th>
                  <th colspan="2" class="text-center week-name" v-for="n in yardGantt.week_list" :key="n">
                    <!-- {{'W.' | translate}}  -->
                    {{n.slice(-2)}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in yardGantt.yards" :key="row.code">
                  <td class="table-b-table-default text-wrap yard-name">
                    <div class="btn-actions d-flex justify-content-start">
                      <b-btn
                        v-has-perms="['yard_yard_retrieve']"
                        class="btn-no-border"
                        variant="outline-primary"
                        size="sm"
                        :title="`${$t('Detail')}`"
                        @click="goToDetail(row.id)"
                      >
                        <i
                          :title="`${$t('Detail')}`"
                          class="fas fa-search"
                        />
                      </b-btn>
                      <div>{{row.code}} {{row.client_name}}</div>
                    </div>
                  </td>
                  <template v-for="n in yardGantt.week_list">
                    <td :class="`${getGanttCellClass(row, n)} table-b-table-default text-nowrap gantt-cell first-gantt-cell`" :key="`first${n}`">
                    </td>
                    <td  :class="`${getGanttCellClass(row, n)} table-b-table-default text-nowrap gantt-cell last-gantt-cell`" :key="`last${n}`">
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </panel>
      </div>
    </portal-target>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
// import ThuxListHeader from '@/components/thux-list/ThuxListHeader'
import { TYPES as YARD_TYPES } from './store'
import { MONTHS } from '@/const'
import { ROUTES as YARD_ROUTES } from '../../yard/yard/router'

export default {
  name: 'YardGantt',
  props: {
    isDetail: { default: false }
  },
  components: { },
  data () {
    return {
      rolePerm: ['yard_yard_gantt'],
      MONTHS,
      showList: true
    }
  },
  computed: {
    ...mapGetters({
      ...YARD_TYPES.GENERIC.dashboard.yard.LIST.GETTERS
    }),
    panelTitle () {
      return this.$t('Yard gantt')
    },
    totalCols  () {
      return (this.yardGantt.week_end - this.yardGantt.week_start) * 2 + 1
    }
  },
  methods: {
    ...mapMutations({
      ...YARD_TYPES.GENERIC.dashboard.yard.LIST.MUTATIONS
    }),
    ...mapActions({
      ...YARD_TYPES.GENERIC.dashboard.yard.LIST.ACTIONS
    }),
    getWeekNumbers (start, stop) {
      return new Array(stop - start).fill(start).map((n, i) => n + i)
    },
    getGanttColumns () {
      const stop = (this.yardGantt.week_end - this.yardGantt.week_start) * 2 + 2
      const start = 1
      return new Array(stop - start).fill(start).map((n, i) => n + i)
    },
    getMonthColSpan (index, weekList) {
      const firstWeek = weekList[0]
      const lastWeek = weekList[weekList.length - 1]
      let otherLastWeek
      const otherFirstWeek = Object.keys(this.yardGantt.months).find((key) => {
        if (key !== index) {
          return this.yardGantt.months[key].includes(firstWeek)
        }
        return undefined
      })
      if (firstWeek !== lastWeek) {
        otherLastWeek = Object.keys(this.yardGantt.months).find((key) => {
          if (key !== index) {
            return this.yardGantt.months[key].includes(lastWeek)
          }
          return undefined
        })
      }
      let colspan = 0
      weekList.forEach(element => {
        if (element === firstWeek && otherFirstWeek) {
          colspan = colspan + 1
        } else if (element === lastWeek && otherLastWeek) {
          colspan = colspan + 1
        } else {
          colspan = colspan + 2
        }
      })
      return colspan
    },
    getGanttCellClass (yard, weekNum) {
      if (weekNum === yard.yard_weeks[0]) {
        return 'cell-green'
      }
      if (weekNum === yard.yard_weeks[yard.yard_weeks.length - 1]) {
        return 'cell-red'
      }
      const durationWeek = yard.yard_weeks.slice(1, -1)
      if (durationWeek.includes(weekNum)) {
        return 'cell-yellow'
      }
      return ''
    },
    goToDetail (yardId) {
      this.$router.push({ name: YARD_ROUTES.YARD_DETAIL, params: { id: yardId } })
    }
  },
  created () {
    this.setYardGantt({})
    this.getYardGantt()
  }
}
</script>
