<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from './store'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import UploadDocumentTable from './UploadDocumentTable.vue'

export default {
  name: 'UploadDocumentList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': UploadDocumentTable
  },
  data () {
    return {
      title: this.$t('Security periodic meeting - Customers'),
      fieldName: 'id',
      searchFields: [],
      showCommonFilters: false,
      showAdvancedSearch: false,
      toggleAdvancedSearch: false,
      showHeader: false,
      rolePerm: 'document_uploaddocument_list'
    }
  },
  computed: {
    ...mapGetters({
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.GETTERS
    }),
    canAddInstance () {
      return false
    },
    canSeeActionSelect () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setOrderBy: UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.MUTATIONS.setOrderBy
    }),
    ...mapActions({
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.ACTIONS
    })
  }
}
</script>
