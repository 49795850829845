<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as TRAINING_TYPES } from './store'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import TrainingTable from './TrainingTable.vue'
export default {
  name: 'TrainingList',
  mixins: [ThuxListMixin],
  props: {
    documentId: {}
  },
  watch: {
    documentId () {
      if (this.documentId) {
        this.getComponentList()
      }
    }
  },
  components: {
    'component-table': TrainingTable
  },
  data () {
    return {
      title: this.$t('Training'),
      fieldName: 'id',
      searchFields: [],
      showCommonFilters: false,
      showAdvancedSearch: false,
      toggleAdvancedSearch: false,
      showHeader: false,
      rolePerm: 'document_uploaddocument_deadline_training_list'
    }
  },
  computed: {
    ...mapGetters({
      ...TRAINING_TYPES.GENERIC.dashboard.training.LIST.GETTERS
    }),
    canAddInstance () {
      return false
    },
    canSeeActionSelect () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setOrderBy: TRAINING_TYPES.GENERIC.dashboard.training.LIST.MUTATIONS.setOrderBy
    }),
    ...mapActions({
      ...TRAINING_TYPES.GENERIC.dashboard.training.LIST.ACTIONS
    }),
    getComponentList () {
      if (this.documentId) {
        this.setFilters({ filter__document__id: this.documentId })
      }
    }
  }
}
</script>
