<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showEditButton="showEditButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
    >
      <template slot="status_name" slot-scope="row">
        <span :class="`fw-600 tag tag-${DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS[row.item.status]}`">
          {{row.item.status_name}}
        </span>
      </template>
      <template slot="is_optional" slot-scope="row">
        <status-indicator :status="row.item.is_optional" />
      </template>

      <template slot="date_start" slot-scope="row">
          {{ row.item.date_start | date}}
      </template>

      <template slot="organization__name" slot-scope="row">
        <span> {{ row.item.organization_typology_name ? `${row.item.organization_name} (${row.item.organization_typology_name})`: row.item.organization_name }}</span>
      </template>

      <template slot="date_end" slot-scope="row">
          <span :class="row.item.is_document_expired ? 'text-red' : ''">
            {{ row.item.date_end | date}}
          </span>
      </template>

      <template slot="yards" slot-scope="row">
        {{row.item.yard_name_short_list}}
      </template>

      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <!-- <b-btn v-if="row.item.is_downloadable || row.item.is_created_by_template"
          variant="outline-primary"
          :title="'Download model' | translate"
          @click="generateDocument(row.item)"
        >
          <i class="fas fa-file-download"></i>
        </b-btn> -->
        <b-btn v-if="row.item.attachment"
          v-has-perms="downloadDocumentRolePerm"
          variant="outline-primary"
          :title="'download' | translate"
          @click="downloadDocument(row.item)"
        >
          <i class="fas fa-download"></i>
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="orgDocumentsRolePerm"
          size="sm"
          :title="`${$t('Organization documents')} ${$t(instanceName)}`"
          @click="goToOrganizationDocuments(row)"
        >
          <i
            :title="`${$t('Organization documents')} ${$t(instanceName)}`"
            class="far fa-file-alt"
          />
        </b-btn>
      </template>
    </thux-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from './store'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import StatusIndicator from '@/components/StatusIndicator'

import { DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS, ORGANIZATION_CUSTOMER_CODE, ORGANIZATION_SUPPLIER_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE } from '@/const'

import { ROUTES as CUSTOMER_ORG_ROUTES } from '../../organization/organization/organization/customer-organization/router'
import { ROUTES as LEAD_ORG_ROUTES } from '../../organization/organization/organization/lead-organization/router'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../../organization/organization/organization/supplier-organization/router'

export default {
  name: 'UploadDocumentTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: {
    StatusIndicator
  },
  data () {
    return {
      DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS,
      fields: [
        {
          key: 'status_name',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },

        {
          key: 'organization__name',
          label: this.$t('Organization'),
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'organization_name',
          custom: true
        },
        {
          key: 'date_start',
          label: this.$t('Validity start'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'date_end',
          label: this.$t('Validity end'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'customer', 'title', 'description', 'validity_start', 'validity_end'],
      fieldName: 'id',
      showPopoverDocStatusModal: false,
      // Permissions
      downloadDocumentRolePerm: ['document_uploaddocument_download_attachment'], // TODO Non funziona
      orgDocumentsRolePerm: ['document_uploaddocument_organization_documents_list']
    }
  },
  computed: {
    ...mapGetters({
      selectAll: UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.GETTERS.selectAll,
      selectedList: UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return false
    },
    showDetailButton () {
      return false
    },
    showEditButton () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setList: UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.MUTATIONS.setList,
      setSelectedList:
        UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.MUTATIONS.setSelectedList,
      setSelectAll: UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.LIST.ACTIONS,
      partialUpdate: UPLOAD_DOCUMENT_TYPES.GENERIC.dashboard.uploaddocument.DETAIL.ACTIONS.partialUpdate
    }),
    showPopoverDocumentStatus (uploadDocument) {
      this.$set(this, 'instance', uploadDocument)
      this.$set(this, 'showPopoverDocStatusModal', true)
    },
    changeUploadDocumentStatus (evt) {
      const formData = { id: this.instance.id, ...evt }
      this.partialUpdate(formData).then(
        () => {
          this.$set(this, 'instance', {})
          this.$set(this, 'showPopoverDocStatusModal', false)
        }
      )
    },
    downloadDocument (item) {
      const fileName = `${item.document_name}.${item.attachment_simple_filename.split('.').pop()}`
      this.download({ id: item.id, action: 'download-attachment', simpleFileName: fileName })
    },
    goToOrganizationDocuments (data) {
      const params = { orgId: data.item.organization, orgType: data.item.default_org_type_code }
      switch (data.item.default_org_type_code) {
        case ORGANIZATION_CUSTOMER_CODE:
          return this.$router.push({ name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DOCUMENTS, params })
        case ORGANIZATION_SUPPLIER_CODE:
          return this.$router.push({ name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DOCUMENTS, params })
        case ORGANIZATION_CUSTOMER_LEAD_CODE:
          return this.$router.push({ name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DOCUMENTS, params })
      }
    },
    reset () {
      this.$set(this, 'instance', {})
      this.$set(this, 'showPopoverDocStatusModal', false)
    }
  }
}
</script>
