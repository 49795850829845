<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as UNILAV_TYPES } from './store'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import UnilavTable from './UnilavTable.vue'
export default {
  name: 'UnilavList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': UnilavTable
  },
  data () {
    return {
      title: this.$t('Unilav - Customers'),
      fieldName: 'id',
      searchFields: [],
      showCommonFilters: false,
      showAdvancedSearch: false,
      toggleAdvancedSearch: false,
      showHeader: false,
      rolePerm: 'document_uploaddocument_deadline_unilav_list'
    }
  },
  computed: {
    ...mapGetters({
      ...UNILAV_TYPES.GENERIC.dashboard.unilav.LIST.GETTERS
    }),
    canAddInstance () {
      return false
    },
    canSeeActionSelect () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setOrderBy: UNILAV_TYPES.GENERIC.dashboard.unilav.LIST.MUTATIONS.setOrderBy
    }),
    ...mapActions({
      ...UNILAV_TYPES.GENERIC.dashboard.unilav.LIST.ACTIONS
    })
  }
}
</script>
