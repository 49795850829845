<template>
  <div class="dashboard">
    <div class="dashboard-grid">
      <yard-notification-document-list :isDetail="true"/>
      <yard-gantt :isDetail="true"/>
      <downloads-list :isDetail="true"/>
      <agreement-document-list :isDetail="true"/>
      <upload-document-list :isDetail="true"/>
      <eligibility-list :isDetail="true"/>
      <unilav-list :isDetail="true"/>
      <training-accordion />
    </div>
  </div>
</template>

<script>
import YardNotificationDocumentList from '../yard-notification-document/YardNotificationDocumentList.vue'
import YardGantt from '../yard-gantt/YardGanttList.vue'
import AgreementDocumentList from '../agreement-document/AgreementDocumentList.vue'
import UploadDocumentList from '../upload-document/UploadDocumentList.vue'
import EligibilityList from '../eligibility/EligibilityList.vue'
import DownloadsList from '../downloads/DownloadsList.vue'
import UnilavList from '../unilav/UnilavList.vue'
import TrainingAccordion from '../training/TrainingAccordion.vue'

export default {
  name: 'dashboard',
  components: {
    YardNotificationDocumentList,
    AgreementDocumentList,
    UploadDocumentList,
    EligibilityList,
    UnilavList,
    TrainingAccordion,
    YardGantt,
    DownloadsList
  },
  data: () => ({
    dataset: [],
    resultset: [],
    displayRange: 3
  })
}
</script>
