<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_NOTIFICATION_TYPES } from './store'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import YardNotificationDocumentTable from './YardNotificationDocumentTable.vue'
export default {
  name: 'YardNotificationDocumentList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': YardNotificationDocumentTable
  },
  data () {
    return {
      title: this.$t('Yard Notifications'),
      fieldName: 'id',
      searchFields: [],
      showCommonFilters: false,
      showAdvancedSearch: false,
      toggleAdvancedSearch: false,
      showHeader: false,
      rolePerm: 'document_uploaddocument_deadline_yard_notification_list'
    }
  },
  computed: {
    ...mapGetters({
      ...YARD_NOTIFICATION_TYPES.GENERIC.dashboard.yardnotificationdocument.LIST.GETTERS
    }),
    canAddInstance () {
      return false
    },
    canSeeActionSelect () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setOrderBy: YARD_NOTIFICATION_TYPES.GENERIC.dashboard.yardnotificationdocument.LIST.MUTATIONS.setOrderBy
    }),
    ...mapActions({
      ...YARD_NOTIFICATION_TYPES.GENERIC.dashboard.yardnotificationdocument.LIST.ACTIONS
    })
  }
}
</script>
