
<template>
  <div :class="!isDetail ? 'wrapper sidebar-collapsed' : ''" v-has-perms="rolePerm">
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed">
        <thux-list-header
          v-if="showHeader"
          :title="title"
          :go-to="goToRouter"
          :icon-active-filters="getIconFiltersFields"
          :intermediate-breadcrumbs="intermediateBreadcrumbs"
        />
        <component-edit
          v-if="showEditForm && editComponent"
          :id="componentId"
          :editParams="editParams"
          @close-form='closeEditForm'
        />
        <component-detail
          v-if="showDetailForm && detailComponent"
          :id="componentId"
          :detailParams="detailParams"
          @close-form='closeDetailForm'
        />

        <advanced-search
          v-show="showAdvancedSearch"
          v-if="toggleAdvancedSearch"
          :fields="searchFields"
          :reset="reset"
          @search="addFiltersToInstance"
          @reset="removeFiltersToInstance"
          @active="activeFilters = $event"
        />
        <panel :title="panelTitle" :no-button="true">
          <template slot="header">
            {{title}}&nbsp;
            <b-btn class="btn btn-outline-primary ml-2" size="sm">
              <i
                :title="`${$t('Organization documents')}`"
                class="far fa-file-alt"
                @click="goToAllDocuments"
              ></i>
            </b-btn>
          </template>
          <thux-list-component
            :fields="getFields"
            :pagination="getPaginationFields"
            @select-per-page="changePerPage"
            @change-page="changePage"
            :action-select="getActionSelectFields"
            @select-action="setAction"
            @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
            @do-action="doAction($event, fieldName)"
            :advanced-search="getAdvancedSearchFields"
            @show-advanced-search="openAdvancedSearch"
            @open-edit-form="openEditForm()"
            @open-detail-form="openDetailForm()"
          >
            <template slot="body">
              <component-table
                :list="list.results"
                :goBack="goBack"
                :params="tableParams"
                @open-edit-form="openEditForm"
                @open-detail-form="openDetailForm"
              />
            </template>
          </thux-list-component>
        </panel>
      </div>
    </portal-target>
    <router-view />
    <portal-target
      v-if="showCommonFilters"
      v-show="showList"
      name="layout-sidebar"
      class="layout-side panel-scroll-full"
    >
      <button
        @click.prevent="closeRightSidebar"
        class="btn btn-primary text-uppercase w-100 mb-4"
        :aria-label="$t('Close')"
      >
        <i class="fas fa-times" />
        {{ $t('Hide sidebar') }}
      </button>
      <common-filters />
    </portal-target>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as AGREEMENT_DOCUMENT_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import AgreementDocumentTable from './AgreementDocumentTable.vue'

import { ROUTES as AGREEMENT_DOCUMENT_ROUTES } from '../../agreement/agreement-document/router'

export default {
  name: 'AgreementDocumentList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': AgreementDocumentTable
  },
  data () {
    return {
      title: this.$t('Agreements'),
      rolePerm: ['agreement_agreementdocument_valid_agreement_documents'],
      fieldName: 'id',
      searchFields: [],
      showCommonFilters: false,
      showAdvancedSearch: false,
      toggleAdvancedSearch: false,
      showHeader: false
    }
  },
  computed: {
    ...mapGetters({
      ...AGREEMENT_DOCUMENT_TYPES.GENERIC.dashboard.agreementdocument.LIST.GETTERS
    }),
    canAddInstance () {
      return false
    },
    canSeeActionSelect () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setOrderBy: AGREEMENT_DOCUMENT_TYPES.GENERIC.dashboard.agreementdocument.LIST.MUTATIONS.setOrderBy
    }),
    ...mapActions({
      ...AGREEMENT_DOCUMENT_TYPES.GENERIC.dashboard.agreementdocument.LIST.ACTIONS
    }),
    getComponentList () {
      if (!hasPerm(this.rolePerm)) {
        return
      }
      this.setOrderBy('validity_end')
      if (this.$route.params.orgId) {
        this.setFilters({ filter__agreement__customer: this.$route.params.orgId })
      } else {
        this.setFilters({ })
      }
    },
    goToAllDocuments () {
      this.$router.push({ name: AGREEMENT_DOCUMENT_ROUTES.AGREEMENT_DOCUMENT_LIST })
    }
  }
}
</script>
