<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll('id')"
      @check-none="checkNone('id')"
      @set-selected-row="setSelectedRow($event, 'id')"
      :show-checks="showChecks"
      :showDetailButton="showDetailButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-detail-form="goToDetail($event)"
    >
      <template slot="validity_end" slot-scope="row">
        <span :class="row.item.is_agreement_document_expired ? 'text-red' : ''">
            {{ row.item.validity_end | date}}
        </span>
      </template>
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn v-if="row.item.document"
          v-has-perms="['agreement_agreementdocument_download_document']"
          variant="outline-primary"
          :title="'download' | translate"
          @click="downloadDocument(row.item)"
        >
          <i class="fas fa-download"></i>
        </b-btn>
        <b-btn
          v-has-perms="['agreement_agreementdocument_list']"
          variant="outline-primary"
          size="sm"
          :title="`${$t('Organization agreements')} ${$t(instanceName)}`"
          @click="goToOrganizationDocuments(row)"
        >
          <i
            :title="`${$t('Organization agreements')} ${$t(instanceName)}`"
            class="far fa-file-alt"
          />
        </b-btn>
      </template>
    </thux-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as AGREEMENT_DOCUMENT_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import moment from 'moment'
import { ORGANIZATION_CUSTOMER_CODE } from '../../../const'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../../organization/organization/organization/customer-organization/router'
import { ROUTES as AGREEMENT_DOCUMENT_ROUTES } from '../../agreement/agreement-document/router'

export default {
  name: 'AgreementDocumentTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      fields: [
        // {
        //   key: 'checks',
        //   label: this.$t('Checks')
        // },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-wrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'agreement__customer__name',
          label: this.$t('Customer'),
          tdClass: 'text-wrap',
          thClass: 'text-wrap',
          sortable: true,
          value: 'organization_name'
        },
        {
          key: 'title',
          label: this.$t('Agreement object'),
          tdClass: 'text-wrap',
          thClass: 'text-wrap',
          sortable: true,
          value: 'title'
        },
        {
          key: 'validity_start',
          label: this.$t('Validity start'),
          type: 'date',
          tdClass: 'text-nowrap',
          thClass: 'text-wrap',
          sortable: true,
          value: 'validity_start'
        },
        {
          key: 'validity_end',
          label: this.$t('Validity end'),
          type: 'date',
          tdClass: 'text-nowrap',
          thClass: 'text-wrap',
          sortable: true,
          value: 'validity_end',
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'customer', 'title', 'description', 'validity_start', 'validity_end'],
      fieldName: 'id',
      showDocumentHistoryModal: false
    }
  },
  computed: {
    ...mapGetters({
      selectAll: AGREEMENT_DOCUMENT_TYPES.GENERIC.dashboard.agreementdocument.LIST.GETTERS.selectAll,
      selectedList: AGREEMENT_DOCUMENT_TYPES.GENERIC.dashboard.agreementdocument.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return hasPerm('agreement_agreementdocument_retrieve')
    }
  },
  methods: {
    ...mapMutations({
      setList: AGREEMENT_DOCUMENT_TYPES.GENERIC.dashboard.agreementdocument.LIST.MUTATIONS.setList,
      setSelectedList:
        AGREEMENT_DOCUMENT_TYPES.GENERIC.dashboard.agreementdocument.LIST.MUTATIONS.setSelectedList,
      setSelectAll: AGREEMENT_DOCUMENT_TYPES.GENERIC.dashboard.agreementdocument.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...AGREEMENT_DOCUMENT_TYPES.GENERIC.dashboard.agreementdocument.LIST.ACTIONS
    }),
    goToOrganizationDocuments (data) {
      this.$router.push({ name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_AGREEMENTS, params: { orgId: data.item.organization_id, orgType: ORGANIZATION_CUSTOMER_CODE } })
    },
    goToDetail (data) {
      this.$router.push({ name: AGREEMENT_DOCUMENT_ROUTES.AGREEMENT_DOCUMENT_DETAIL, params: { id: data } })
    },
    isAgreementExpired (date) {
      return moment(date) < moment()
    },
    downloadDocument (item) {
      const fileName = `Contratto_${item.organization_name}.${item.simple_filename.split('.').pop()}`
      this.download({ id: item.id, action: 'download-document', simpleFileName: fileName })
    }
  }
}
</script>
